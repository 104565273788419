<template>
    <PageElementWrapper no-padding>
        <div class="xl:px-72 grid md:grid-cols-2 grid-cols-1 gap-4">
            <StrapiLandingPageKachelnWrapper>
                <NuxtLink
                    to="application"
                    class="p-4 w-full">
                    <img
                        class="inline align-middle h-[100px] w-full"
                        src="~assets/application.svg"
                        alt="Juleica Antrag stellen">
                    <h2 class="mt-5 text-3xl">
                        Antag stellen
                    </h2>
                    <div>
                        Beantrage jetzt deine Juleica
                    </div>
                </NuxtLink>
            </StrapiLandingPageKachelnWrapper>
            <StrapiLandingPageKachelnWrapper>
                <NuxtLink
                    to="myjuleica"
                    class="p-4 w-full">
                    <img
                        class="inline align-middle h-[100px] w-full"
                        src="~assets/myjuleica.svg"
                        alt="Meine Juleica">
                    <h2 class="mt-5 text-3xl">
                        Meine Juleica
                    </h2>
                    <div>
                        Zugriff auf deine digitale Juleica
                    </div>
                </NuxtLink>
            </StrapiLandingPageKachelnWrapper>
            <!--<StrapiLandingPageKachelnWrapper>
                <a
                    href="/service"
                    class="p-4 w-full">
                    <img
                        class="inline align-middle h-[100px] w-full"
                        src="~assets/backend.svg"
                        alt="Juleica Verwaltung">
                    <h2 class="mt-5 text-3xl">
                        Verwaltung
                    </h2>
                    <div>Juleica Verwaltung</div>
                </a>
            </StrapiLandingPageKachelnWrapper>-->
        </div>
    </PageElementWrapper>
</template>

<script setup>
</script>
